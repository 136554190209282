export const journeyFlowItems = {
  myPointOfView: {
    "title": "journeyItems.myPointOfView.title",
    "icon": "eyes_looking",
    "steps": [
      {
        "stepItems": [
          {
            "type": "Intro",
            "mandatory": false,
            "icon": "eyes_looking",
            "title": "זה לא כיף לריב.. בא/י ננסה להבין מה קרה",
            "description": " בשלב הזה נחשוב על מה שקרה עם{{partnerName}} מנקודת מבטך",
            "state": "whatHappened",
            "side": "mySide"
          }
        ]
      },
      {
        "title": "unnamedMirror",
        "stepItems": [
          {
            "type": "FeelsCard",
            "mandatory": true,
            "title": "מה את/ה מרגיש/ה עכשיו?",
            "label": "מה את/ה מרגיש/ה עכשיו?",
            "state": "whatYouNeed",
            "cardWrapType": "feelTowardsSituation",
            "length": "3"
          }
        ]
      },
      {
        "title": "unnamedMirror",
        "stepItems": [    
          {
            "type": "TextArea",
            "mandatory": false,
            "label": "מה קרה? למה את/ה מרגיש/ה ככה?",
            "title":  "מה קרה? למה את/ה מרגיש/ה ככה?",
            "rows": "4",
            "state": "what-happened",
            "id": "what-happened",
            "name": "what-happened"
          }
        ]
      },
      {
        "title": "mirroring.titleNeeds",
        "stepItems": [
          {
            "type": "NeedCard",
            "mandatory": true,
            "title": "mirroring.whatYouNeed",
            "label": "mirroring.whatYouNeed",
            "state": "whatYouNeed2",
            "cardWrapType": "whatYouNeed",
            "length": "3"
          }
        ]
      }
    ]
  },
  assumePointOfView: {
    "title": "journeyItems.assumePointOfView.title",
    "icon": "shoe",
    "steps": [
      {
        "stepItems": [
          {
            "type": "Intro",
            "mandatory": false,
            "icon": "shoe",
            "title": "journeyItems.assumePointOfView.title",
            "description": " בשלב הזה ננסה להכנס לנעליים של {{partnerName}}, ונשער מה עובר עליו/ה",
            "state": "whatHappened",
            "side": "mySide"
          }
        ]
      },
      {
        "title": "assumePointOfView.assumeFeel-title",
        "stepItems": [
          {
            "type": "FeelsCard",
            "mandatory": true,
            "label": "mirroring.assumeFeelTowardsSituation",
            "title": "mirroring.assumeFeelTowardsSituation",
            "state": "assumeFeelTowardsSituation",
            "cardWrapType": "assumeFeelTowardsSituation"
          }
        ]
      },
      {
        "title": "assumePointOfView.assumeFeel-title",
        "stepItems": [
          {
            "type": "TextArea",
            "mandatory": false,
            "label": "אם היינו שואלים את {{partnerName}} מה קרה, מה לדעתך הוא/היא היה אומר/ת?",
            "state": "assumeWhyFeelTowardsSituation",
            "rows": "4",
            "id": "assumeWhyFeelTowardsSituation",
            "name": "assumeWhyFeelTowardsSituation"
          }
        ]
      },
      {
        "title": "assumePointOfView.assumeNeed-title",
        "stepItems": [
          {
            "type": "NeedCard",
            "mandatory": true,
            "title": "mirroring.assumeWhatPartnerNeed",
            "label": "mirroring.assumeWhatPartnerNeed",
            "state": "assumeWhatPartnerNeed",
            "cardWrapType": "assumeWhatPartnerNeed"
          }
        ]
      }
    ]
  },
  AIStep: {
    "title": "AI STEP",
    "icon": "AI",
    "steps": [
      {
        "stepItems": [
          {
            "type": "AIStep",
            "mandatory": false,
            "icon": "AI",
            "title": "AI step",
            "description": "this is the description of AI step",
            "state": "AISummary",
            "side": "mySide"
          }
        ]
      }
    ]
  },
  DummyDynamicStep: {
    "title": "Dummy dynamically created",
    "icon": "dummy",
    "steps": [
      {
        "stepItems": [
          {
            "type": "FeelsCard",
            "mandatory": false,
            "icon": "dummy",
            "title": "dummy step",
            "description": "this is the description",
            "state": "dummyStep",
            "side": "mySide"
          }
        ]
      }
    ]
  }
}

export const buildJourneyFromStepIds = (journeyStepIds) => {
  return journeyStepIds.map((journeyItemStepId) => journeyFlowItems[journeyItemStepId])
}