import React, { useState, useContext } from "react";
import "./CatbearsFeedbackBtn.scss"
import { useTranslation } from 'react-i18next';
import { GlobalContext } from "context/GlobalContext";

const CatbearsFeedbackBtn = ({ }) => {

    const {ShowFeedback, setShowFeedback} = useContext(GlobalContext);
    const {t} = useTranslation();

    function closeFeedbackPopup () {
      setShowFeedback(false)
    }

    const openFeedbackPopup = () => {
        setShowFeedback(true)
    }

    return (<>
        <div onClick={openFeedbackPopup} className="CatbearsFeedbackBtn-container">
            <img src="/assets/icons/icon-feedback.png" alt="The Catbears - feedback icon" />
            <div className="CatbearsFeedbackBtn" >{t("general.feedback")}</div>
        </div>
    </>)
}

export default CatbearsFeedbackBtn;