import React, {useState, useRef, useEffect, useContext} from "react";
import ReactPlayer from 'react-player';
import { GlobalContext } from "context/GlobalContext";

// import SeekBar from "./SeekBar";
import setPropertyVh from '../../utils/setPropertyVh';
import Loading from "components/Loading/Loading";
import { useTranslation } from 'react-i18next';
import './VideoPlayer.scss'
import getHourAndDate from 'utils/getHourAndDate';

function VideoPlayer ({
        URL, 
        IsCurrentSelectedStepItemCompleted, 
        setVideoExited,
        onContinue,
        onBack,
        onExit,
        addEvent,
        stepItemId,
        setVideoSkipped,
        seek, 
        volume, 
        VideoPlayerNoControls, 
        VideoPlayerLoop, 
        classes,
        CurrentSelectedStepItem,
        isSkippable
    }) {

    const [CHEATS, setCHEATS] = useState(0)
    const [continueAvailable, setcontinueAvailable] = useState(false)
    const {ShowFeedback, setShowFeedback} = useContext(GlobalContext);
    const {t} = useTranslation();
    setPropertyVh();
    const videoEl = useRef(null);    
    const [IsPlayVideo, setIsPlayVideo] = useState(true)
    const [ShowVideoControlls, setShowVideoControlls] = useState(false)
    const [showContinueButton, setShowContinueButton] = useState(false)
    const [ShowSpinner, setShowSpinner] = useState(false)
    const [VideoOnEnd, setVideoOnEnd] = useState(false)                        
    
    const togglePlayPause = () => {
        if(IsPlayVideo){
            setIsPlayVideo(false)
            setShowVideoControlls(true)
        }else{
            console.log("hit 1  ")
            setIsPlayVideo(true)
            setShowVideoControlls(false) 
        }
    }

    const pauseAndShowControls = () => {
        console.log("pauseAndShowControls")
            setShowVideoControlls(true)
            setIsPlayVideo(false)
    }

    const handleStartVideo = () => {
        if(!VideoPlayerLoop) {
            setVideoOnEnd(false);
        }
        setShowVideoControlls(false);
        setIsPlayVideo(true)
        setShowSpinner(false)
        setShowContinueButton(false)
    };

    const handleRestartVideo = () => {
        videoEl.current.seekTo(0)
        handleStartVideo()
        if(!VideoPlayerLoop) {
            setVideoOnEnd(false);
        }
    }
    const handleVideoEnded = () => {
        if(VideoPlayerLoop) {
            handleRestartVideo()
        }else {
            setVideoOnEnd(true)
            pauseAndShowControls()
        }
    };
    

    const handleContinueVideo = () => {
            setIsPlayVideo(false)
            setShowVideoControlls(false)
            setShowContinueButton(false)
            addEvent && addEvent("continue", "stepItem", "VideoPlayer", CurrentSelectedStepItem, stepItemId, getHourAndDate());
            onContinue && onContinue();
        }
        const handleSkipVideo = () => {  
            setIsPlayVideo(false)
            setShowVideoControlls(false)
            setVideoOnEnd(false);
            addEvent && addEvent("continue", "stepItem", "VideoPlayer", CurrentSelectedStepItem, stepItemId, getHourAndDate());
            onContinue && onContinue();
    }

    const handleExitVideo = () => {
        setIsPlayVideo(false)
        setShowVideoControlls(false)
        setVideoOnEnd(false);
        onExit()
    }

    const handlePlayVideo = () => {
        setIsPlayVideo(true)
        setShowVideoControlls(false)
    }

    
    ///seek bar
        const [played, setPlayed] = useState(0);
        const playerRef = useRef(null);
        const progressRef = useRef(null);
      
        const handleSeekChange = (e) => {
          const newValue = parseFloat(e.target.value);
          setPlayed(newValue);
          playerRef.current.seekTo(newValue);
        };
      
        const handleProgress = (state) => {
            
            setPlayed(state.played);
            
        //   if (!progressRef.current.seeking) {
        //     setPlayed(state.played);
        //   }
        }

    ///seek bar end
    // const handleFullScreen = () => {
    //     const player = document.getElementById('video-player');
    //     if (player) {
    //         if (player.requestFullscreen) {
    //             player.requestFullscreen();
    //         } else if (player.mozRequestFullScreen) { // Firefox
    //             player.mozRequestFullScreen();
    //         } else if (player.webkitRequestFullscreen) { // Chrome, Safari, and Opera
    //             player.webkitRequestFullscreen();
    //         } else if (player.msRequestFullscreen) { // IE/Edge
    //             player.msRequestFullscreen();
    //         }
    //     }
    // };


    useEffect(() => {
        const handleKeyDown = (event) => {
        event.preventDefault();
        if(ShowVideoControlls){
            switch (event.key) {
                case ' ':
                if(!VideoOnEnd) handlePlayVideo()
                break
                case 'Enter':
                if(IsCurrentSelectedStepItemCompleted || VideoOnEnd) handleContinueVideo();
                break;
                case 'Delete':
                case 'Backspace':
                handleRestartVideo();
                break;
                case 'Escape':
                handleExitVideo();
                break;
                default:
                break;
            }
            }else{
            switch (event.key) {
                case ' ':
                case 'Enter':
                case 'Escape':
                case 'Delete':
                case 'Backspace':
                    
                pauseAndShowControls()
                break;
            }
        }

        }
        !ShowFeedback && window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, [ShowVideoControlls, IsPlayVideo , IsCurrentSelectedStepItemCompleted, ShowFeedback]);

    return (

        <>   
            <div className={"video-container noselect " + (classes ? classes : "") + " " + (VideoPlayerNoControls ? "disableClick" : "")}>
                {ShowSpinner ? <Loading  backgroundTransparent = {true} /> : ""}
                {ShowVideoControlls && !VideoPlayerNoControls &&
                    <div className="video-controlls noselect">
                        {/* <SeekBar 
                            watchedTime = {"?"}
                            setWatchedTime = {"?"}
                            duration = {"?"}
                            onSeek = {"?"}
                        /> */}
                        {((IsCurrentSelectedStepItemCompleted && VideoOnEnd) || (!IsCurrentSelectedStepItemCompleted && VideoOnEnd)) &&
                        <div onClick={handleContinueVideo} className="video-controlls__button video-controlls__button--continue">
                            <img className="video-controlls__img" src="/assets/icons/icon_video-control_continue.png" alt="catbears_video_control_icon"/>
                            <div className="video-controlls__txt" >{t("general.continue")}</div>
                        </div> 
                        }
                        {(isSkippable || (IsCurrentSelectedStepItemCompleted && !VideoOnEnd)) && 
                            <div onClick={handleSkipVideo} className="video-controlls__button">
                                <img className="video-controlls__img" src="/assets/icons/icon_video-control_continue.png" alt="catbears_video_control_icon"/>
                                <div className="video-controlls__txt" >{t("general.skip")}</div>
                                </div> 
                        }
                        {!VideoOnEnd && 
                            <div onClick={togglePlayPause} className="video-controlls__button">
                                <img className="video-controlls__img" src={"/assets/icons/icon_video-control_" + (IsPlayVideo ? "pause" : "play")  + ".png"} alt="catbears_video_control_icon"/>
                                <div className="video-controlls__txt" >{IsPlayVideo ? t("general.pause") : t("general.play") }</div>
                            </div>
                        }
                 
                        <div onClick={handleExitVideo} className="video-controlls__button">
                            <img className="video-controlls__img" src="/assets/icons/icon_video-control_exit.png" alt="catbears_video_control_icon"/>
                            <div className="video-controlls__txt" >{t("general.exit")}</div>
                        </div>
                        <div className="video-controlls__button" onClick={handleRestartVideo}>
                            <img className="video-controlls__img" src="/assets/icons/icon_video-control_restart.png" alt="catbears_video_control_icon"/>
                            <div className="video-controlls__txt" >{t("general.restart")}</div>
                        </div> 
                    </div>                  
                }  
                <div className={"video-controlls-background " + (ShowVideoControlls && !VideoPlayerNoControls ? " " : " video-controlls-background--hide")}onClick={!VideoOnEnd ? togglePlayPause : ""} ></div>
                    <ReactPlayer
                        playing={IsPlayVideo}
                        id="video-player"
                        onEnded={handleVideoEnded}
                        onStart={handleStartVideo}
                        url={URL}
                        seek={seek}
                        ref={videoEl}
                        volume={volume ? 1 : 0}
                        onBuffer={() => setShowSpinner(true)}
                        onBufferEnd={() => setShowSpinner(false)}
                        onPlay={() => setShowSpinner(false)}
                        onReady={() => setShowSpinner(false)} 
                        onProgress={handleProgress}
                        // playbackRate={4}
                    />
                <div className="seekBar">
                    <div className="seekbar-played"style={{
                    width: `${played * 100}%`}}> </div>
                </div>
            </div>
        </>
    )
}

export default VideoPlayer